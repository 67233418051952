import React, { useState, useEffect } from 'react';
import { ListItemAO, ListItemMT } from '../snippets/ListItem';
import ListSection from '../sections/ListSection';
import NavBar from '../components/common/NavBar';
import CustomHero from '../components/common/CustomHero';
import Divider from '../snippets/decorative/Divider';
import NavigationButton from '../components/common/NavigationButton';
import { getAllUserSelections, saveQuestionnaireSelection } from '../utils/persistence/dataPersistence';

const AddOnList = ({onSubmit, option}) => {
    const [optionSelections, setOptionSelections] = useState([]);
    const [expandedItem, setExpandedItem] = useState(null);
    const [disabledSubmitButton, setIsButtonDisabled] = useState(true);

    useEffect(() => {
        const optionIsValid = !option.settings?.required || optionSelections.length > 0;
        setIsButtonDisabled(!(optionIsValid));
    }, [optionSelections]);

    const handleSelection = (choiceId, selections, setSelections, isMultiSelect) => {
        if (isMultiSelect) {
            setSelections(prevSelections =>
                prevSelections.includes(choiceId)
                    ? prevSelections.filter(id => id !== choiceId)
                    : [...prevSelections, choiceId]
            );
        } else {
            setSelections(prevSelections =>
                prevSelections.includes(choiceId)
                    ? [] // Deselect if already selected in single select mode
                    : [choiceId] // Select if not selected
            );
        }
    };

    const handleSelectionOption = (choiceId) => {
        handleSelection(choiceId, optionSelections, setOptionSelections, option.settings?.multi_select);
    };

    const handleExpandedToggle = (choiceId) => {
        expandedItem === choiceId ? setExpandedItem(null) : setExpandedItem(choiceId);
    }

    const submitForm = (e) => {
        saveQuestionnaireSelection(optionSelections, option.id);
        onSubmit(e);
    }

    return(
        <div className='page-layout__stacked'>
            <NavBar/>
            <CustomHero
                firstLine="Enhance your"
                secondLine="experience"
                subheading="Boost recovery, relieve pain, and deepen relaxation with specialized add-ons."
            />

            <form onSubmit={submitForm} id='#add-on-form' className='page-width width-100 align-self__center section__fill-remainder align-content__vertical' style={{ margin: 0 }}>
                <ListSection
                    sectionClass={'ao'}
                >
                    {option && (
                        option.choices.map((choice, index) => {
                            const isLastChoice1 = index === option.choices.length - 1;

                            return (
                                <div key={choice.id} className={`list-item-wrapper card`}>
                                    <ListItemAO
                                        id={choice.id}
                                        title={choice.title}
                                        description={choice.description}
                                        descriptionBullets={choice.bullet_points}
                                        price={choice.price}
                                        onSelected={() => handleSelectionOption(choice.id)}
                                        onToggle={() => handleExpandedToggle(choice.id)}
                                        isExpanded={expandedItem && expandedItem === choice.id}
                                        isSelected={optionSelections.includes(choice.id)}
                                        imgSrc={choice.imgUrl}
                                        additionalClasses={'normal-text-small-bold'}
                                        >
                                    </ListItemAO>
                                    
                                    {(!isLastChoice1 || optionSelections.includes(choice.id)) && <Divider />}
                                </div>
                            );
                        })
                    )}
    
                </ListSection>
                <section className='section-nav'>
                    <NavigationButton description="Continue" isDisabled={disabledSubmitButton}/>
                </section>
            </form>
        </div>
    );
}

export default AddOnList;