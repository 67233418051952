import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  dataNames,
  saveSensitiveUserData,
} from '../utils/persistence/dataPersistence';
import CalendarForm from '../calendarComponents/CalendarForm';
import Therapist from '../calendarComponents/objects/Therapist';

import { reqDaysOffObject } from '../__tests__/RequestDaysOffOIbject';
import { snakeToCamel } from '../utils/parsers/objectParse.js';
import { parseTSRANGE } from '../utils/calendar/calendarTools.js';
import { baseURL } from '../api/urls.js';

import CustomHero from '../components/common/CustomHero.jsx';
import NavBar from '../components/common/NavBar.jsx';

const AppointmentDetails = ({ nextPath, prevPath }) => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate(nextPath);
  };
  const handlePrevPath = () => {
    navigate(prevPath);
  };
  const handleAppointmentSelection = async (dateObj) => {
    saveSensitiveUserData(dateObj, dataNames.DATE);
    handleNavigation();
  };

  const [therapist, setTherapist] = useState(null);
  useEffect(() => {
    Promise.all([
      fetch(`${baseURL}appointments/schedule`, { mode: 'cors' }),
      fetch(`${baseURL}availability?id=1`, { mode: 'cors' }),
    ])
      .then((response) => {
        return response;
      })
      .then(async ([dbAppointments, dbTherapistAvailability]) => {
        let resolvedAppointments = await dbAppointments.json();
        let resolvedAvailability = await dbTherapistAvailability.json();
        let [obj] = resolvedAvailability;

        let tempTherapist = new Therapist(
          'Hector',
          reqDaysOffObject,
          snakeToCamel(parseTSRANGE(obj)),
          snakeToCamel(resolvedAppointments)
        );

        setTherapist(tempTherapist);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <NavBar callback={handlePrevPath}></NavBar>

      <div className="height-100 flex-column-direction">
        <CustomHero
          firstLine="Schedule Your"
          secondLine="Session"
          subheading="Find a moment that fits your schedule."
        ></CustomHero>
        {therapist ? (
          <>
            <CalendarForm
              therapist={therapist}
              handleRoute={handleAppointmentSelection}
            ></CalendarForm>

            {/* <button onClick={handleNavigation}>Go To Deposit</button> */}
          </>
        ) : (
          <h1>waiting </h1>
        )}
        {/* <div className="white-color" onClick={handleNavigation}>
        bypass
      </div> */}
      </div>
    </>
  );
};

export default AppointmentDetails;
