import React, { useEffect, useState } from 'react';
import RadioSelection from './RadioSelection.js';

/**
 * Makes the payment selection section with radio buttons
 * in review page.
 * @param {Int} total the total cost of the appointment .
 * @param {Int} deposit the deposit amount for the appointment.
 * @param {String} appointmentDate the string representation of the appointment date.
 * @returns
 */
const PaymentOption = ({ total, deposit, appointmentDate }) => {
  const [paymentForToday, setPaymentForToday] = useState(total);

  return (
    <form id="option-form">
      <div className="flex-row-direction flex-space-between bottom-margin ">
        <h3>Your Payment Today</h3>
        <h2>${paymentForToday}</h2>
      </div>
      <RadioSelection
        firstLine="One time payment"
        secondLine={`$${total} due today`}
        setPaymentForToday={setPaymentForToday}
        paymentAmount={total}
        defaultOn={true}
      ></RadioSelection>

      <RadioSelection
        firstLine="Hold my spot"
        secondLine={`$${deposit} due today, $${
          total - deposit
        } due on ${appointmentDate}`}
        setPaymentForToday={setPaymentForToday}
        paymentAmount={deposit}
        defaultOn={false}
      ></RadioSelection>
    </form>
  );
};

export default PaymentOption;
