import { baseURL, emailURL } from '../urls.js';

export const sendReceipt = async (dataObject) => {
  try {
    let response = await fetch(`${emailURL}/receipt`, {
      method: 'POST',
      body: JSON.stringify(dataObject),
      headers: {
        'Content-type': 'application/json',
      },
      mode: 'cors',
    });
  } catch (error) {
    console.log(error);
  }
};
